.sol-cookie-message {
  .sol-clearfix;
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 9999;
  width: 320px;
  border-top-left-radius: 5px;
  background-color: @cookie-bg;
  color: @cookie-text;
  padding: 10px 30px 8px 18px;
  font-size: 14px;
  & a {
    color: @cookie-text;
    text-decoration: underline;
  }
  & .sol-cookie-ok {
    .focus-outline;
    .no-appearance;
    display: inline-block;
    padding: 5px 10px;
    border-radius: 4px;
    background-color: @cookie-ok-bg;
    color: @cookie-ok-text;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    cursor: pointer;
    float: right;
    margin-top: 3px;
  }
}
.sol-editor-bottom {
  bottom: 60px !important;
}
