.sol-article-wrapper {
  max-width: 828px;
  margin-left: auto;
  margin-right: auto;
  .sv-image-portlet {
    position: relative;
    overflow: hidden;
    .sol-article-date {
      position: absolute;
      left: -45px;
      top: -45px;
      width: 100px;
      height: 100px;
      padding-top: 47px;
      padding-left: 31px;
      border-radius: 100%;
      background-color: @news-item-date-bg;
      color: @news-item-date-color;
      text-align: center;
      @media @sm {
        left: -40px;
        top: -40px;
        width: 90px;
        height: 90px;
        padding-top: 40px;
      }
      &-day {
        display: block;
        font-size: 16px;
        line-height: 11px;
        font-weight: 700;
        @media @sm {
          font-size: 14px;
          line-height: 7px;
        }
      }
      &-month {
        font-size: 12px;
        line-height: 22px;
        display: block;
      }
    }
  }
  & .pagecontent {
    .sol-clearfix;
  }
}

.sol-news-menu {
  .sol-menu-item[data-expanded="false"] {
    .sol-menu-list {
      display: none;
    }
  }
}
