.sol-tool-nav {
  float: right;
  margin-top: 15px;
  position: relative;
  @media @md {
    display: block;
    vertical-align: top;
    float: none;
    margin-top: 0;
    text-align: center;
  }

  &-item {
    display: inline-block;
    float: left;
    @media @sm {
      float: none;
    }
    & > a,
    & > button {
      .focus-outline;
      .no-appearance;
      margin-left: 10px;
      margin-right: 5px;
      font-weight: 700;
      color: @header-top-text;
      font-size: 12px;
      cursor: pointer;
      text-decoration: none;
      font-family: @main-font;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      @media @sm {
        color: @header-top-mobile-text;
        //margin: 0 9px 9px;
      }
      &:hover {
        color: @color-3;
      }
     
      & i.fa {
        margin-right: 5px;
        font-size: 15px;
      }

    }
    &-badge {
      background: #ff8700;
      padding: 2px 8px;
      border-radius: 14px;
      display: inline-block;
      text-align: center;
      color: #fff;
      vertical-align: middle;
      line-height: 20px;
      margin: -2px 4px 0;
      font-size: 15px;
      font-weight: 400;
    }
    & .sol-close {
      .focus-outline;
      .no-appearance;
      position: absolute;
      top: 0;
      right: 0;
      color: @header-top-text;
      width: 20px;
      height: 20px;
      text-align: center;
      line-height: 20px;
      padding: 0;

      & i.fa {
        font-size: 11px;
      }
    }
    +.sol-tool-nav-search {
      background:@color-3;
      padding: 10px 20px;
      margin-top:-10px;
      border-radius: 22px;
      margin-left: 20px;
      & button, a {
        color: #fff;
        margin-left: 0;
        &:hover {
          color: darken(@white,10%)
        }
      }
      @media @md {
        margin-top: -5px;
        padding: 5px 15px;
      }
      @media @sm {
        margin-top: 10px;
      }
    }
  }

  &-box {
    position: absolute;
    width: 189px;
    margin-top: 10px;
    margin-left: -60px;
    padding: 17px;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, .2);
    text-align: center;
    z-index: 1;
    cursor: default;
    @media @md-strict {
      margin-left: 0;
      right: 45px;
    }
    @media @sm {
      left: 50%;
      margin-left: -109px;
      width: 215px;
      padding: 12px;
    }

    & a.sol-btn-round {
      .transition(border);
      float: left;
      width: 30px;
      height: 30px;
      margin-right: 5px;
      margin-left: 5px;
      color: @header-top-box-share-icon;
      border: 2px solid @header-top-box-share-border;
      border-radius: 50%;
      font-size: 15px;
      padding-top: 1px;
      text-align: center;
      @media @sm {
        width: 40px;
        height: 40px;
        font-size: 21px;
        padding-top: 0;
        line-height: 37px;
      }
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        border-color: @header-top-box-share-hover;
      }
    }

    &-translate {
      padding: 12px 17px 9px;
    }
  }
  .sol-tool-nav-box {
    position:fixed;
  }
}
// READSPEAKER

.sv-readspeaker_expandingplayer-portlet {
  position: relative;

  & > a {
    display: none;
  }

  & div[id^=readspeaker_player].rsexpanded {
    position: absolute;
    right: 0;
    height: auto;
    width: auto !important;
    padding: 17px 17px 22px;
    border-radius: 4px;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 5px 0;
    text-align: center;
  }
}

.sol-translate {
  margin-right: 0 !important;
}