.sol-top-nav {
  .sol-clearfix;
  display: inline-block;
  vertical-align: top;

  &-item {
    .focus-outline;
    float: left;
    padding: 15px 30px 12px;
    //border-right: 1px solid rgba(255, 255, 255, 0.2);
    color: @header-menu-text;
    font-size: 22px;
    line-height: 1.2em;
    font-weight: 300;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
    vertical-align: top;
    text-decoration: none;
    //max-width: 125px;
    height: 65px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    //text-transform: uppercase;
    //letter-spacing: 1.2px;
    //border-bottom: 4px solid transparent;
    .transition(background-color);
    &:first-child {
      //border-left: 1px solid rgba(255, 255, 255, 0.2);
    }
    &:hover {
      background-color: @header-menu-hover;
      //border-bottom: 4px solid @color-1;
    }
    &.sol-active {
      background-color: @header-menu-active;
      font-weight: 400;
      //border-bottom: 4px solid lighten(@color-1,10%);
    }
    @media @lg {
      padding: 15px 20px 12px;
    }
  }
}

