// Import custom icon font 
@fa-at-icon-elhandel: "\e900";
@fa-at-icon-felanmalan: "\e901";
@fa-at-icon-fiber: "\e902";
@fa-at-icon-fjarrvarme: "\e903";
@fa-at-icon-flyttanmalan: "\e904";

@font-face {
    font-family: 'arvikateknik';
    src:
      url('/webdav/files/System/resources/fonts/arvikateknik.ttf?2qw62b') format('truetype'),
      url('/webdav/files/System/resources/fonts/arvikateknik.woff?2qw62b') format('woff'),
      url('/webdav/files/System/resources/fonts/arvikateknik.svg?2qw62b#arvikateknik') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  
  i[class*=" fa-at-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'arvikateknik' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .fa-at-icon-elhandel {
    &:before {
      content: @fa-at-icon-elhandel;
    }
  }
  .fa-at-icon-felanmalan {
    &:before {
      content: @fa-at-icon-felanmalan;
    }
  }
  .fa-at-icon-fiber {
    &:before {
      content: @fa-at-icon-fiber;
    }
  }
  .fa-at-icon-fjarrvarme {
    &:before {
      content: @fa-at-icon-fjarrvarme;
    }
  }
  .fa-at-icon-flyttanmalan {
    &:before {
      content: @fa-at-icon-flyttanmalan;
    }
  }
  