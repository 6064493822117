.sol-startpage-top-images {
  position: relative;
  a {
    text-decoration: none;
  }
  &-item {
    height:450px;
    background-position: 50% 50%;
    background-size: cover;
    position: relative;
    &__caption {
      position: absolute;
      bottom: 0;
      padding: 10px 85px 35px 85px;
      min-height: 85px;
      width: 100%;
      background-color: rgba(255, 255, 255, 0.8);
      color: @color-3;
      display: block;
      flex-direction: column;

      & h2 {
        margin: 0;
        color: @color-3;
      }
      &--mobile {
        display: none;
        @media @sm {
          display: block;
          background-color: @color-3;
          padding: 10px 40px 35px 40px;
          min-height: 150px;
          h2, span {
            margin: 0;
            color: @white;
          }
        }
        @media @xs {
          min-height: 190px;
        }
      }
      @media @sm {
        display: none;
      }
    }
    & .sol-image-caption__wrapper {
      margin-top: 10px;
    }
    @media @sm {
      height: 240px;
    }
    @media @xs {
      height: 170px;
    }
  }
  .slick-prev {
    position: absolute;
    bottom: 35px;
    z-index: 1;
    left: 50px;
    cursor: pointer;
    color: @color-3;
    padding: 0px 8px 0px 7px;
    border-radius: 50%;
    border: 2px solid @color-3;

    &::before {
      font-size: 26px;
    }
    @media @sm {
      color: @white;
      border: 2px solid @white;
      bottom: 50%;
      margin-bottom: -125px;
      left: 10px;
    }
    @media @xs {
      margin-bottom: -85px;
    }
  }
  .slick-next {
    position: absolute;
    bottom: 35px;
    z-index: 1;
    right: 50px;
    cursor: pointer;
    color: @color-3;
    padding: 0px 7px 0px 8px;
    border-radius: 50%;
    border: 2px solid @color-3;

    &::before {
      font-size: 26px;
    }
    @media @sm {
      bottom: 50%;
      margin-bottom: -125px;
      right: 10px;
      color: @white;
      border: 2px solid @white;
    }
    @media @xs {
      margin-bottom: -85px;
    }
  }

  & .slick-dots {
    position: absolute;
    bottom: -10px;
    width: 100%;
    padding: 0;
    list-style: none;
    text-align: center;

    & li {
      position: relative;
      display: inline-block;
      width: 20px;
      height: 20px;
      margin: 0 5px;
      padding: 0;
      cursor: pointer;

      & button {
        font-size: 0;
        line-height: 0;
        display: block;
        width: 20px;
        height: 20px;
        padding: 5px;
        cursor: pointer;
        color: transparent;
        border: 0;
        outline: none;
        background: transparent;
        
        &:before {
          font-family: 'slick';
          font-size: 34px;
          line-height: 20px;
          position: absolute;
          top: 0;
          left: 0;
          width: 20px;
          height: 20px;
          content: '•';
          text-align: center;
          opacity: .25;
          color: @color-3;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          @media @sm {
            color: @white;
          }
        }
      }
    }
    & .slick-active {
      & button:before {
          opacity: .75;
          color: @color-3;
          @media @sm {
            color: @white;
          }
      }
    }
    @media @sm {
      bottom: 0px;
      //top: 185px;
    }
    @media @xs {
      //top: 145px;
    }
  }
  
  &-content {
    position: absolute;
    width: 660px;
    left: 0px;
    top: 127px;
    right: 0px;
    z-index: 10;
    margin-left: auto;
    margin-right: auto;
    border-radius: 3px;
    overflow: hidden;
    @media @sm {
      position: static;
      width: auto;
      border-radius: 0;
    }
    & .sol-top-search {
      padding: 30px 35px 30px 15px;
      background-color: @top-content-search-bg;
      @media @sm {
        background-color: @top-content-search-bg-phone;
      }
    }
  }
}

