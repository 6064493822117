/* Lessfile: startpage/energy_production.less */
.sol-startpage__local {
    text-align:center;
    padding:15px 0 35px 0;

    & h2 {
        color: @news-item-date-bg;
        font-style: italic;
        font-weight: 400;
        margin-bottom: -60px;
    }

    & p:first-of-type {
        font-size: 72px;
        color: rgba(121, 195, 98, 0.2);
    }

    & p {
        font-style: italic;
        margin-top: -50px !important;
    }
}
.sol-energy__production {
    text-align:center;
    padding: 5px 0 25px 0;
    position: relative;

    & h2 {
        color: @news-item-date-bg;
        font-style: italic;
        font-weight: 400;
    }

    & i {
        margin-top: 22px;
        font-size: 72px;
        color: rgba(121, 195, 98, 0.2);
        top: 0;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
    }

    & strong {
        font-size: 38px;
        color: rgba(121, 195, 98, 1);
        margin-bottom: 5px;
    }

    & p {
        //margin-top: -30px;
        margin-bottom: 5px;
    }
}