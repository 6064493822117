.sol-article-item {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
  padding: 0 20px 30px 230px;
  height: 110px;
  //border-top: 2px solid @news-item-border;
  border-radius: 0;
  //border:1px solid @gray-2;
  background-color: @news-item-bg;
  //box-shadow: 0 0 5px 0 rgba(0, 0, 0, .1);
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  text-decoration: none;
  @media @md {
    padding-left: 160px;
  }
  @media @sm {
    padding-left: 20px;
    padding-top: 210px; 
    height: auto;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  &.sol-no-img {
    padding-left: 80px;
    @media @sm {
      padding-top: 20px;
    }
  }
  & &-img {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 200px;
    background-position: 50% 50%;
    background-size: cover;
    text-decoration: none;
    @media @md {
      width: 130px;
    }
    @media @sm {
      width: 100%;
      height: 200px;
    }
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      .transition(background-color);
    }
  }
  & &-date {
    position: absolute;
    left: -45px;
    top: -45px;
    width: 100px;
    height: 100px;
    padding-top: 47px;
    padding-left: 31px;
    border-radius: 100%;
    background-color: @news-item-date-bg;
    color: @news-item-date-color;
    text-align: center;
    z-index: 2;
    @media @sm {
      left: -40px;
      top: -40px;
      width: 90px;
      height: 90px;
      padding-top: 40px;
    }
    &-day {
      display: block;
      font-size: 16px;
      line-height: 11px;
      font-weight: 700;
      @media @sm {
        font-size: 14px;
        line-height: 7px;
      }
    }
    &-month {
      font-size: 12px;
      line-height: 22px;
      display: block;
    }
  }

  & &-desc {
    @media @sm {
      display: none;  
    }
  }

  &.sol-no-img &-date {
    background-color: @news-item-date-bg;
  }
  & &-heading {
    display: block;
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 400;
    .transition(color);
  }
  & &-event-info {
    .sol-clearfix;
    margin-top: 10px;
    &-item {
      margin-right: 13px;
      font-size: 11px;
      float: left;
      & i {
        margin-right: 6px;
        color: @event-item-icon-bottom;
      }
    }
  }
  &:hover &-img:before {
    background-color: @news-item-img-hover;
  }
  &:hover &-heading {
    color: @news-item-text-hover;
    text-decoration: underline;
  }
}
.sol-startpage-news .sv-channel {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  & .sv-channel-item {
    width: 50%;
    @media @md {
      width: 100%;
    }
    &-img {
      @media @md {
        width: 130px;
      }
      @media @sm {
        width: 100%;
        height: 100%;
      }
      }
    }
    }

.sol-driftinfo-archive {
  .sol-article-item {
    height: auto;
  }
}
