.sol-menu-container {
  margin-top: 48px;
  overflow: visible;
  & .sol-menu-list {
    display: none;
    width: 100%;
    &-expanded-init {
      display: block;
    }
  }
  & .sol-ul-container {}
  & .sol-menu-item {
    position: relative;
    &[data-menu-level="1"] .sol-menu-item-link {
      .focus-outline;
      .sol-relative;
      display: block;
      padding: 20px 34px 20px 20px;
      border-top: 1px solid @submenu-border;
      color: @submenu-text;
      text-decoration: none;
      .transition(~  'color, background-color, border');
    }
    &[data-menu-level="2"] .sol-menu-item-link {}
    &[data-menu-level="3"] {
      .sol-menu-item-link {
        padding-left: 35px;
        line-height: 14px;
      }
      & .sol-menu-button {
        height: 55px;
      }
    }
    &[data-menu-level="4"] .sol-menu-item-link {
      padding-left: 45px;
    }
    &[data-menu-level="5"] .sol-menu-item-link {
      padding-left: 55px;
    }
    &[data-expanded="true"][data-menu-level="1"] {
      & > .sol-menu-item-link,
      & > .sol-menu-item-link:hover {
        color: @submenu-text-expanded;
        background-color: @submenu-bg-expanded;
        border-top-color: @submenu-border-expanded;
      }
      & > .sol-menu-button i.fa {
        color: @submenu-text-expanded;
      }
      & > .sol-menu-list > ul {
        border-left: 2px solid @submenu-border-expanded;
      }
    }
    &[data-expanded="true"] {
      & > .sol-menu-button i.fa {
        transform: rotate(90deg);
      }
    }
    &[data-expanded="true"][data-has-children="false"] > .sol-menu-item-link,
    &[data-is-current="true"] > .sol-menu-item-link,
    &[data-is-current="true"] > .sol-menu-item-link:hover {
      color: @submenu-text-current;
      background-color: @submenu-bg-current;
      border-color: @submenu-border-current;
      font-weight: 700;
    }
    &[data-expanded="true"][data-has-children="false"] > .sol-menu-item-link {
      & i.fa {
        color: @submenu-text-expanded;
      }
    }
    &[data-has-children="false"] > .sol-menu-item-link {
      padding-right: 5px;
    }
    & > .sol-menu-item-link:hover {
      color: @submenu-text-hover;
      background-color: @submenu-bg-hover;
    }
    & .sol-menu-button {
      .no-appearance;
      .focus-outline;
      position: absolute;
      right: 0;
      width: 34px;
      text-align: right;
      font-size: 17px;
      top: 0;
      //bottom: 1px;
      height: 63px;
      padding-right: 20px;
      cursor: pointer;
      & > i.fa {
        position: absolute;
        top: 50%;
        margin-top: -9px;
        .transition(all);
      }
    }
    &[data-node-type="sv:folder"] {
      & > .sol-menu-button {
        width: 100%;
        left: 0;
      }
    }
  }
}
