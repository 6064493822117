.sol-popular-pages {
  .sol-clearfix;
  background-color: @top-content-popular-bg;
  padding: 10px 15px 5px;
  @media @sm {
    background-color: @top-content-popular-bg-phone;
  }
  & > a,
  & > div {
    color: @top-content-popular-text;
    float: left;
    margin-bottom: 5px;
    @media @sm {
      font-size: 13px;
    }
  }
  & > div {
    display: inline-block;
    margin-right: 10px;
    font-weight: 600;
  }
  & > a {
    margin-right: 15px;
    text-decoration: none;
    .transition(opacity);
    &:hover {
      opacity: 1;
    }
    & i.fa {
      width: 18px;
      height: 18px;
      margin-right: 8px;
      border: 1px solid @top-content-popular-text;
      border-radius: 50%;
      text-align: center;
      padding-left: 1px;
      padding-top: 1px;
    }
  }
}

.sol-popular-pages-start a {
  display: block;
  position: relative;
  text-decoration: none;
  padding: 20px 35px 20px 20px;
  border-bottom: 1px solid #f1f1f1;
  color: #333a57;
  font-size: 16px;
  line-height: 20px;
  transition-property: color;
  transition-duration: 200ms;
  transition-timing-function: ease;
}
 .sol-popular-pages-start a i.fa {
  font-size: 14px;
  width: 18px;
  height: 18px;
  margin-right: 8px;
  color: #5788b9;
  border: 1px solid #5788b9;
  border-radius: 50%;
  text-align: center;
  padding-left: 1px;
  padding-top: 1px;
  position: absolute;
  right: 9px;
  top: 50%;
  margin-top: -9px;
}
.sol-contact-block-startpage li {
  display: block;
  position: relative;
  text-decoration: none;
  padding: 20px 10px 20px 35px;
  border-bottom: 1px solid #f1f1f1;
  color: #333a57;
  font-size: 16px;
  line-height: 20px;
  transition-property: color;
  transition-duration: 200ms;
  transition-timing-function: ease;
}
.sol-contact-block-startpage li i.fa {
  font-size: 18px;
  width: 18px;
  height: 18px;
  margin-right: 8px;
  color: #5788b9;
  text-align: center;
  padding-left: 1px;
  padding-top: 1px;
  position: absolute;
  left: 9px;
  top: 50%;
  margin-top: -9px;
}
.sol-contact-block__error {
  display: flex !important;
  flex-wrap: wrap;
  & p {
    margin: 0;
  }
  & img {
    display: none;
  }
  & span {
    line-height: 24px;
  }
  & a {
    color: black !important;
  }
}