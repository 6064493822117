.sol-startpage-wrapper {
  .sol-top {
    position: relative;
  }
  & .sol-startpage-content-bottom,
  & .sol-startpage-content-top {
    & > div:first-child {
      padding-right: 15px;
      @media @md {
        padding-right: 10px;
      }
    }
    & > div:last-child {
      padding-left: 15px;
      @media @md {
        padding-left: 10px;
      }
    }
  }
  & .sol-startpage-content-bottom {
    margin-top: 35px;
    @media @md {
      margin-top: 15px;
    }
    @media @sm {
      margin-top: 0;
    }
    .sol-left {
      h2.font-heading-2 {
        margin-top: 0;
        margin-bottom: 10px;
      }
    }
    .sol-right {
      padding-top: 33px;
      @media @sm {
        padding-top: 0;
      }
    }
  }
}
