.sol-form,
.sol-form-decoration {
  padding: 20px 0 40px;
  border-radius: 5px;
  background-color: @form-bg;
  margin-bottom: 20px;
  @media @md {
    padding: 20px;
  }
  @media @sm {
    padding-right: 10px;
    padding-left: 10px;
  }
  & h2 {
    margin-top: 0;
  }
  & form {
    & input[type='email'],
    & input[type='number'],
    & input[type='password'],
    & input[type='tel'],
    & input[type='text'],
    & input[type='url'],
    select,
    textarea {
      .input-text;
      &:invalid {
        .input-text-invalid;
      }
      &:required:valid {
        .input-text-valid;
      }
      & ~ span.font-normal {
        margin-top: 10px;
      }
    }
    & textarea {
      height: auto;
    }
    select {
      .input-select;
    }
    & .sol-form-field {
      margin-bottom: 10px;
    }
    & .sol-label-container,
    & .sv-field-title-container,
    & .sv-login-input-group {
      & > label {
        .input-label;
      }
      & > span {
        display: block;
        margin-bottom: 12px;
        font-size: 14px;
        font-weight: 700;
      }
    }
    & .sv-form-datefield {
      & > div {
        width: 50%;
        margin: 0 !important;
        &:nth-of-type(1) {
          padding-right: 5px;
        }
        &:nth-of-type(2) {
          padding-left: 5px;
        }
        & select {
          min-width: 100%;
          margin-bottom: 10px;
        }
      }
    }
    & br {
      display: none;
    }
    .font-normal {
      display: block;
    }
    & .sv-formField {
      margin-bottom: 0;
      @media @sm {
        width: 100%;
        padding: 0;
      }
    }
    & input[type='checkbox'] {
      .input-checkbox;
    }
    & input[type='radio'] {
      .input-radio;
    }
    & button[type='submit'],
    & input[type='submit'] {
      .sol-btn;
      margin-top: 20px;
      padding-left: 25px;
      padding-right: 25px;
      & > i.fa {
        margin-right: 11px;
        margin-left: -15px;
      }
    }
    & .sol-form-divider {
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px dashed @form-divider;
    }
  }
}
