.sol-box-decoration {
  .sol-clearfix;
  //border-radius: 4px;
  background-color: @box-decoration-bg;
  //box-shadow: 0 0 5px 0 rgba(0, 0, 0, .1);
  margin-bottom: 20px;
  &-header {
    display: block;
    padding: 20px;
    border-bottom: 4px solid #e6e6e6;
    //border-top-left-radius: 4px;
    //border-top-right-radius: 4px;
    color: @box-decoration-header-text;
    color:@box-decoration-header-bg;
    text-align: left;
    text-decoration: none;
    line-height: 20px;
    font-weight: 700;
    font-size: 18px;
    margin: 0;
    text-transform: uppercase;
    letter-spacing: 1px;
    a & {
      .focus-outline;
    }
  }
  .sv-text-portlet.sol-btn-small .font-btn {
    margin-bottom: 5px;
  }
}
.sol-divider-decoration {
  margin-top: 30px;
  margin-bottom: 30px;
  border-top: 1px solid @divider-decoration;
}
.sol-simple-box-decoration {
  margin-top: 25px;
  padding: 30px;
  //border: 1px solid @simple-box-decoration-border;
  //border-radius: 3px;
  background-color: @simple-box-decoration-bg;
  &.sol-simple-box-decoration-alt {
    background-color: @simple-box-alt-bg;
  }
  & > div {
    display: block;
    margin-bottom: 5px;
  }
}
.sv-editing-mode {
  .sol-error-box,
  .sol-warning-box {
    display: block;
  }
}
.sol-error-box,
.sol-warning-box {
  display: none;
  padding: 10px 15px;
  border-radius: 5px;
  margin-bottom: 20px;
}
.sol-warning-box {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
}
.sol-error-box {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}
